// --------------------------------------------------
// Print
// --------------------------------------------------
@import '../core/core';
@import './_variables';

// print stylesheet
* {
  background: transparent !important;
  box-shadow: none !important;
  color: @print-text-color !important; /* 1 */
  text-shadow: none !important;
}

@page {
  margin: @print-page-margin;
}

a,
a:visited {
  text-decoration: underline;
}

a[href]:after {
  content: " (" attr(href) ")";
}

// Don't show links for images, or javascript/internal links
a[href^="#"]:after,
a[href^="javascript:"]:after {
  content: "";
}

abbr[title]:after {
  content: " (" attr(title) ")";
}

blockquote,
pre {
  border: .0625rem solid @print-pre-color;
  page-break-inside: avoid;
}

h2,
h3,
p {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

img,
tr {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

thead {
  display: table-header-group; /* http://cbrac.co/Q6s1o2 */
}
